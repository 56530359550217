import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { InviteUserService } from '@pages/first-time-onboarding/services/invite-user.service';
import { nonFlickerLoader } from '@shared/rxjs-operators/non-flicker-loader';
import { patch } from '@ngxs/store/operators';
import {
  CheckUserExistence,
  InviteUser,
  InviteUserCompleted,
  SelectOnboardingOption,
} from '@pages/first-time-onboarding/store/actions/invite-user.actions';
import { OnboardingOption } from '@pages/first-time-onboarding/models/onboarding-option.enum';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { ToastService } from '@core/toast/toast.service';

interface IInviteUserStateModel {
  userExists?: boolean;
  selectedOnboardingOption?: OnboardingOption;
  userExistsLoading: boolean;
  inviteUserLoading: boolean;
  userExistsError: boolean;
  inviteUserError: boolean;
}
@State<IInviteUserStateModel>({
  name: 'inviteUserOnboarding',
  defaults: {
    userExistsLoading: false,
    userExistsError: false,
    inviteUserLoading: false,
    inviteUserError: false,
  },
})
@Injectable()
export class InviteUserState {
  private readonly inviteUserService = inject(InviteUserService);
  private readonly toastService = inject(ToastService);

  @Selector()
  static userExists(state: IInviteUserStateModel): boolean | undefined {
    return state.userExists;
  }

  @Selector()
  static selectedOnboardingOption(state: IInviteUserStateModel): OnboardingOption | undefined {
    return state.selectedOnboardingOption;
  }

  @Selector()
  static userExistsLoading(state: IInviteUserStateModel): boolean {
    return state.userExistsLoading;
  }

  @Selector()
  static inviteUserLoading(state: IInviteUserStateModel): boolean {
    return state.inviteUserLoading;
  }

  @Selector()
  static userExistsError(state: IInviteUserStateModel): boolean {
    return state.userExistsError;
  }

  @Selector()
  static inviteUserError(state: IInviteUserStateModel): boolean {
    return state.inviteUserError;
  }

  @Action(SelectOnboardingOption)
  selectOnboardingOption(context: StateContext<IInviteUserStateModel>, { onboardingOption }: SelectOnboardingOption): void {
    context.setState(patch<IInviteUserStateModel>({ selectedOnboardingOption: onboardingOption }));
  }

  @Action(CheckUserExistence, { cancelUncompleted: true })
  checkUserExistence(context: StateContext<IInviteUserStateModel>, { userEmail }: CheckUserExistence): Observable<boolean | undefined> {
    return nonFlickerLoader(this.inviteUserService.doesUserExist(userEmail), 500).pipe(
      tap(userExists => {
        if (userExists === undefined) {
          context.patchState({ userExistsLoading: true });
          return;
        }

        context.patchState({
          userExists,
          userExistsLoading: false,
          userExistsError: false,
        });
      }),
      catchError(() => {
        context.patchState({
          userExistsError: true,
          userExistsLoading: false,
        });
        return EMPTY;
      })
    );
  }

  @Action(InviteUser)
  inviteUser(context: StateContext<IInviteUserStateModel>, { user }: InviteUser): Observable<void> {
    if (context.getState().inviteUserLoading) {
      return EMPTY;
    }

    context.patchState({ inviteUserLoading: true });

    return this.inviteUserService.inviteUser(user).pipe(
      tap(() => context.dispatch(new InviteUserCompleted())),
      finalize(() => context.patchState({ inviteUserLoading: false })),
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === HttpStatusCode.BadRequest && errorResponse.error === 'UserCreatedButEmailDomainNotAllowed') {
          this.toastService.showWarningToast('first-time-onboarding.toasts.user-invited-but-domain-not-allowed');
          context.dispatch(new InviteUserCompleted());
        }

        return EMPTY;
      })
    );
  }
}
